import React from "react";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import QueueIcon from "@material-ui/icons/Queue";
import ListAltIcon from "@material-ui/icons/ListAlt";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ReportIcon from "@material-ui/icons/Report";
import RedeemIcon from "@material-ui/icons/Redeem";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import PeopleIcon from "@material-ui/icons/People";
import PieChartIcon from "@material-ui/icons/PieChart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import EventIcon from "@material-ui/icons/Event";
import ComputerIcon from "@material-ui/icons/Computer";
import CallEndIcon from "@material-ui/icons/CallEnd";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FeedbackIcon from "@material-ui/icons/Feedback";

export default [
  {
    pages: [
      {
        title: "Dashboard",
        href: "/dashboard",
        icon: DashboardIcon,
      },
      {
        title: "Global Flowboard",
        href: "/global_flowboard",
        icon: ComputerIcon,
      },
      {
        title: "Patient Report",
        href: "/patient_report",
        icon: AssignmentIcon,
      },
      {
        title: "Doctor List",
        href: "/doctor_list",
        icon: QueueIcon,
      },
      // {
      //   title: 'Verify Doctor',
      //   href: '/doctor_verification',
      //   icon: VerifiedUserIcon,
      // },
      {
        title: "Center List",
        href: "/center_list",
        icon: FormatListBulletedIcon,
      },
      {
        title: "Plans",
        href: "/plans",
        icon: CardTravelIcon,
      },
      {
        title: "MSO list",
        href: "/mso_list",
        icon: ListAltIcon,
      },
      {
        title: "Verify Users",
        href: "/user_verification",
        icon: VerifiedUserIcon,
      },
      {
        title: "User List",
        href: "/user_list",
        icon: PersonAddIcon,
      },
      {
        title: "Camp",
        href: "/camp_list",
        icon: FormatListBulletedIcon,
      },
      // {
      //   title: 'Notification',
      //   href: '/notification',
      //   icon: NotificationImportantIcon,
      // },
      {
        title: "Reports",
        href: "/report",
        icon: ReportIcon,
      },
      {
        title: "Roles List",
        href: "/role_list",
        icon: PeopleIcon,
      },
      // {
      //   title: "Section List",
      //   href: "/section_list",
      //   icon: PieChartIcon,
      // },
      {
        title: "Promo Code",
        href: "/promocode",
        icon: RedeemIcon,
      },
      {
        title: "Medical Problem",
        href: "/medical_phrase",
        icon: SortByAlphaIcon,
      },
      {
        title: "Leaves",
        href: "/leave",
        icon: EventIcon,
      },
      {
        title: "Temp Drugs",
        href: "/temp_drug",
        icon: LocalHospitalIcon,
      },
      {
        title: "Unanswered Call",
        href: "/unanswered_call",
        icon: CallEndIcon,
      },
      {
        title: "Issues",
        href: "/issue",
        icon: ContactSupportIcon,
      },
      {
        title: "Feedback History",
        href: "/feedback_history",
        icon: FeedbackIcon,
      },
      {
        title: "Change Password",
        href: "/change_password",
        icon: LockOpenIcon,
      },
    ],
  },
];
