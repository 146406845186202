import React, { Fragment, useEffect,useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme  } from '@material-ui/styles';
import { Drawer, Divider, Paper,  Typography } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';
import Notify from '../../../../assets/img/notify2.png'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PricingModal from './Notes/NotesModal'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ActionCableConsumer } from "react-actioncable-provider";
import axios from '../../../../utils/axios1'

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    // overflowY: 'auto'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - 189px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    width: '145px'
  },
  drawerPaper: {
    // width: drawerWidth,
    width:192,
    backgroundColor:'#3cb0b3',
    // overflowY: 'hidden'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
 
    padding: '11px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    // marginLeft:'-14px' 
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    // marginTop: theme.spacing(2),
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset"
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  profile: {
    cursor:'pointer',
    fontSize: '30px'
  }
}));
const drawerWidth = 240;
const NavBar = props => {
  const { openMobile, onMobileClose, className} = props;

  const classes = useStyles();
  const router = useRouter();
  // const session = useSelector(state => state.session);

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [message, setMessage] = useState("");

  const [openSnack, setOpenSnack] = useState(true);
  const [severity, setSeverity] = useState("info");

  useEffect(()=>{

    if(props.history.location.pathname === "/dashboard"){
      document.getElementById('backIcon').disabled=true
      document.getElementById('backIcon').style.color="lightblue"
      console.log()
      }else{
        document.getElementById('backIcon').disabled=false
        document.getElementById('backIcon').style.color="white"
      }

  },[props.history.location.pathname])
  
  const handlePricingOpen = () => {
    setPricingModalOpen(true);
  };

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogOut = () =>{

    if (localStorage.getItem("jwt") !== '' || localStorage.getItem("jwt") !== undefined) {
      let token = "Bearer " + localStorage.getItem("jwt");
      let id = localStorage.getItem("user");
      axios.get(`/logout/${id} `,  { headers: { Authorization: token } }).then(response => {
        console.log(response);
        alert(response.data.message);
        if(response !== null || response !== '' || response  !== undefined){
          localStorage.clear();
          props.history.push("/auth/login");
        }
        

      }).catch(error => {
        if (error.response.data !== "") {
          alert(error.response.data.error);
          if(error.response.status == '401'){
            localStorage.clear();
            props.history.push("/auth/login");
          }
        } else {
          alert(error.response.statusText);
        }
      });
    }

  }
  const handleBackButton = ()=>{
    // let token = localStorage.getItem("jwt");
    // if(token){
      if(props.history.location.pathname === "/dashboard"){
     console.log()
      }else{
        props.history.goBack()
      }
  }
 
  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
  }, [router.location.pathname]);

  const navbarContent = (
    <div style={{   marginLeft:'1px',padding:'0px' }} className={classes.content}>
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation
            style={{ fontSize:'12px' }}
            component="div"
            key={list.pages}
            pages={list.pages}
            // title={list.title}
          />
        ))}
      </nav>
    </div>
  );

  const handleReceived = (message) => {
    console.log(message);
    console.log("Recieved");
    try {
      if (message) {
        setMessage(message.data);
        setOpenSnack(true);
      } else {
        setMessage("res not OK");
        setOpenSnack(true);
        setSeverity("warning");
      }
    } catch (err) {
      setMessage("Error from catch");
      setOpenSnack(true);
      setSeverity("error");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setMessage("");
  };

  return (
    <Fragment>
            {message ? (
        <Snackbar
          open={openSnack}
          style={{ top: "10%" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          //  autoHideDuration={50000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            style={{ backgroundColor: "#af4c95" }}
          >
            {message}
          </Alert>
        </Snackbar>
      ) : null}
      <div className={classes.root}>    
      <AppBar
      style={{ backgroundColor:'#3cb0b3' }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
          <ActionCableConsumer
              channel="NotificationChannel"
              onReceived={handleReceived}
            ></ActionCableConsumer>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            data-testid="drawer-open-icon"
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
         
          <Typography variant="h6" noWrap>
         
          </Typography>
          <div>
            <ArrowBackIcon id="backIcon" onClick={handleBackButton}/>
          </div>
          
          
         <div style={{ position:'absolute',left:'78%' }}>
         <Button
        //  style={{ marginTop:'-22px' }}
        variant="contained"
        color="secondary"
        onClick={handlePricingOpen}
        className={classes.button}
        startIcon={<NoteAddIcon />}
      >
        Notes
      </Button>
         </div>
         <div style={{ position:'absolute',left:'89%' }}>
         <ExitToAppIcon
         style={{ cursor :'pointer',marginTop:8 }}
         data-testid="admin-logout-icon"
          onClick={handleLogOut}
         />
      </div>
         
        </Toolbar>
        <PricingModal
        onClose={handlePricingClose}
        open={pricingModalOpen}
      />
       
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose} data-testid="drawer-close-icon">
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        
        <div style={{  minWidth:0,width:0 }}
            // {...rest}
            className={clsx(classes.root, className)}
          >
            {navbarContent}
          </div>
        {/* <Divider /> */}
       
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
    </div>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default withRouter(NavBar) ;

